import "../../static/assets/css/style.css"
import React from "react"
import {Link} from "gatsby"
import SEO from "../components/seo"
import Lottie from "react-lottie"
import * as animationData from "../images/lottie/3sBlockLogo.json"
import {OutboundLink} from "gatsby-plugin-google-analytics"

const _lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  className: `ascrg`,
}
const IndexPage = () => (
  <div>
    <SEO title={`HOME`}></SEO>
    <div className="title">
      <div className="title__inside">
        <h1>
          <span>TRANS</span> <span>BOOKS</span>
        </h1>
        <ul>
          <li><a href="https://mirror.xyz/0x766e54d4020Fe5529d64c692697060C3FFFf420E" target={`_blank`}>BLOG</a></li>
        </ul>
      </div>
    </div>
    {/*<div className="head">*/}
    {/*  <div className="head__images js-slick-hero">*/}
    {/*    <div className="head__images__rt-fixedRatio">*/}
    {/*      <div className="head__images__rt-fixedRatio__content">*/}
    {/*        <Link to={`/downloads/exhibition/`}>*/}
    {/*          <Lottie options={_lottieOptions} width={300} height={150} />*/}
    {/*</Link>*/}
    {/*</div>*/}
    {/*</div>*/}
    {/*</div>*/}
    {/*</div>*/}

    <div className={`head-box`}>
      <div className={`ad-box`}>
        <div className={`ad-box__image`}>
          <img src="/assets/img/news-tsutaya-ginza.png" alt=""/>
        </div>
        <div className={`ad-box__content`}>
            <h1>銀座蔦屋書店でポップアップ</h1>
            <p>GINZA SIX 5周年の特別企画が開催中。これまでの出展アーティストが勢揃い！</p>
            <p><a href={`https://mirror.xyz/0x766e54d4020Fe5529d64c692697060C3FFFf420E/pkzrAyj7rTRjRfVVaTmciLlTPyUP9qlpyL_Hb4VMokM`} target={`_blank`}>ポップアップのお知らせ</a></p>
        </div>
      </div>
    </div>


    <div className="c-wrap">
      <div className={`top-2col`}>
        <div className="top-2col__left">
          <h2 className="discription">
            過去と未来のまんなかで
            <br/>
            広がり続けるいまの「本」を考える、
            <br/>
            メディアなんでも書店。
          </h2>
        </div>
        <div className="top-2col__right">
          <div className="concept">
            <p>
              TRANS BOOKS
              は「本」をデジタルやアナログを超えたメディア、表現を考えるきっかけを提供してくれるプラットフォームであると捉えています。
            </p>
            <p className="c-link-box">
              <Link to="/downloads/">オンラインストアへ</Link>
            </p>
            <p className="c-link-box">
              <Link to="/podcast/">Podcast 配信中！</Link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <section className="event-list">
      <div className="c-wrap">
        <h2 className="event-list__title">過去の開催</h2>

        {/* 2019 */}
        <ul className="event-list__archive">
          <li className="event-list__archive__item">
            <div className="event-item">
              <div className="event-item__visual">
                <div className="event-item__visual__inner">
                  <Link to="/2019/">
                    <img src="/2019/assets/images/cover.jpg" alt=""/>
                  </Link>
                </div>
                <div>
                  <p className="c-link-box">
                    <a href="/2019/">特設ウェブサイトへ</a>
                  </p>
                  <p className="c-link-box is-gray">
                    <a
                      href="https://archive.transbooks.center/2019/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      出展作品アーカイブ
                    </a>
                  </p>
                </div>
              </div>
              <div className="event-item__content">
                <h3 className="event-item__content__title">TRANS BOOKS 2019</h3>
                <p className="event-item__content__info">
                  2019年11月23日(土)-24日(日) at TAM COWORKING TOKYO
                  <br/>
                  Open 11:00 - 18:30 / 入場料 200 円
                </p>

                <h4 className="event-item__content__h4">参加アーティスト</h4>
                <ul className="event-item__content__list">
                  <li>
                    <dl>
                      <dt>映像作家</dt>
                      <dd>荒木 悠</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>酷暑観光会</dt>
                      <dd>modern fat (伊藤ガビン + 井上泰一)</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd>exonemo</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd>尾焼津早織</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>
                        新たな Q
                        を投げかけるための実験的プロダクトデザインユニット
                      </dt>
                      <dd>●. (Q / period)</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>クリエイティブ・コミュニティスペース</dt>
                      <dd>∧°┐</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザインのためのビジュアルスタジオ</dt>
                      <dd>ノース & ラナー + !Mediengruppe Bitnik</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト + 山口情報芸術センター[YCAM]</dt>
                      <dd>contact Gonzo + YCAMバイオ・リサーチ</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>映像作家 / 写真家</dt>
                      <dd>島田大介</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>イラストレーター</dt>
                      <dd>しみずけいた</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>写真家</dt>
                      <dd>竹久直樹</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>歌手</dt>
                      <dd>永原真夏</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックアーティスト + コピーライター</dt>
                      <dd>沼田摩耶 + 川原綾子</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>研究機関</dt>
                      <dd>HAUS++ (久保田晃弘 + HAUS)</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー + 出版社</dt>
                      <dd>松田行正 + 牛若丸</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd>山形一生</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>プログラマー / アーティスト</dt>
                      <dd>UCNV</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー + イラストレーター</dt>
                      <dd>横山 雄 + DOOKS</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>
                        デザイン / イラスト / マンガ / アニメーション / 図 /
                        など
                      </dt>
                      <dd>よシまるシン</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アートユニット</dt>
                      <dd>lololol.net</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>

        {/* 2018 */}
        <ul className="event-list__archive">
          <li className="event-list__archive__item">
            <div className="event-item">
              <div className="event-item__visual">
                <div className="event-item__visual__inner">
                  <img src="2018/assets/images/cover.jpg" alt=""/>
                </div>
                <div>
                  <p className="c-link-box">
                    <a href="/2018/">特設ウェブサイトへ</a>
                  </p>
                  <p className="c-link-box is-gray">
                    <a
                      href="https://archive.transbooks.center/2018/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      出展作品アーカイブ
                    </a>
                  </p>
                </div>
              </div>
              <div className="event-item__content">
                <h3 className="event-item__content__title">TRANS BOOKS 2018</h3>
                <p className="event-item__content__info">
                  2018年11月24日(土)-25日(日) at TAM COWORKING TOKYO
                  <br/>
                  Open 11:00 - 18:30 / 入場無料
                </p>

                <h4 className="event-item__content__h4">参加アーティスト</h4>
                <ul className="event-item__content__list">
                  <li>
                    <dl>
                      <dt>船先案内人</dt>
                      <dd>
                        阿児つばさ、雨宮庸介、飯沢未央、梅田哲也、九鬼みずほ、さわひらき、辰巳量平、田中有紀、西光祐輔、hyslom、船川翔司、松井美耶子、山本麻紀子、柳本牧紀
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>彫刻家・美術家</dt>
                      <dd>飯田竜太</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>制作・執筆+アーティスト</dt>
                      <dd>伊東友子+時里充</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>UMISHIBAURA</dt>
                      <dd>パブリッシャー</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>本作りの相談業務＋レーザープリント印刷・製本工房</dt>
                      <dd>edition.nord consultancy + poncotan w&g</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>架空紙幣作家</dt>
                      <dd>olo</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>古書店員</dt>
                      <dd>齋藤祐平</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>解釈者</dt>
                      <dd>mmm(サンマロ)</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>会社員</dt>
                      <dd>鹿</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>写真家</dt>
                      <dd>新津保建秀</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>テキストエディタ</dt>
                      <dd>stone</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>Photographer+Design Studio</dt>
                      <dd>TADA+STUDIO PT.</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd>永田康祐</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>リソグラフ印刷スタジオ</dt>
                      <dd>Hand Saw Press</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>アートユニット</dt>
                      <dd>パーフェクトロン</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>プログラマー</dt>
                      <dd>hitode909</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>小説家+グラフィックデザイナー・ブックデザイナー</dt>
                      <dd>福永信+仲村健太郎</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>メタメディア コレクティブ</dt>
                      <dd>PROTOROOM</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>アニメーション作家</dt>
                      <dd>山中澪+池亜佐美</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>プログラマ</dt>
                      <dd>youpy</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>有料メールマガジン発行 / 編集者・写真家</dt>
                      <dd>ROADSIDERS 都築響一</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>美術家 / タレント</dt>
                      <dd>渡邉朋也</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>

        {/* 2017 */}
        <ul className="event-list__archive">
          <li className="event-list__archive__item">
            <div className="event-item">
              <div className="event-item__visual">
                <div className="event-item__visual__inner">
                  <img src="2017/assets/images/cover.jpg" alt=""/>
                </div>

                <div>
                  <p className="c-link-box">
                    <a href="/2017/">特設ウェブサイトへ</a>
                  </p>
                  <p className="c-link-box is-gray">
                    <a
                      href="https://archive.transbooks.center/2017"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      出展作品アーカイブ
                    </a>
                  </p>
                </div>
              </div>
              <div className="event-item__content">
                <h3 className="event-item__content__title">TRANS BOOKS 2017</h3>
                <p className="event-item__content__info">
                  2017年11月4日(土)-5日(日) at TAM COWORKING TOKYO
                  <br/>
                  Open 11:00 - 18:30 / 入場無料
                </p>

                <h4 className="event-item__content__h4">参加アーティスト</h4>
                <ul className="event-item__content__list">
                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd>荒牧悠</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト ✕ グラフィックデザイナー</dt>
                      <dd> 飯沢未央 ✕ 畑ユリエ</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd> 浦川通</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>ÉKRITS編集長 / ディレクター</dt>
                      <dd> 大林寛</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>編集者・タイポグラフィ研究</dt>
                      <dd> 古賀稔章</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>書店 ✕ 花屋</dt>
                      <dd> コ本や ✕ 小宮花店</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>ライター</dt>
                      <dd> 齋藤あきこ</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティストブックサテライト展示</dt>
                      <dd> ARTISTS’ BOOK EXHIBITION SENDAI⇄TOKYO</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>研究者 / 映像作家 / 多摩美術大学専任講師</dt>
                      <dd> 菅俊一</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー</dt>
                      <dd> 鈴木哲生</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>自由研究制作ユニット</dt>
                      <dd>study tables (tadahi・関真奈美)</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>ウェブデザイナー</dt>
                      <dd> 田中良治</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd> 谷口暁彦・谷口千絵</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>字書き</dt>
                      <dd> 出宰漱太郎</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>マンガ家</dt>
                      <dd> てらおか現象</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>コールセンター勤務</dt>
                      <dd> 西岡真知子</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー、多摩美術大学教授</dt>
                      <dd> 永原康史</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー</dt>
                      <dd> Hyunho Choi</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>電子出版プラットフォーム</dt>
                      <dd> BCCKS</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー</dt>
                      <dd> 松本弦人</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>グラフィックデザイナー</dt>
                      <dd> 室谷かおり</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>イラストレーター</dt>
                      <dd> 山本悠</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>アーティスト</dt>
                      <dd> やんツー</dd>
                    </dl>
                  </li>

                  <li>
                    <dl>
                      <dt>書店</dt>
                      <dd> 余白書店</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <div className="footer">
      <div className="c-wrap">
        <h2 className="footer__title">TRANS BOOKS 運営委員会</h2>

        <div className="footer__credits">
          <p>
            <a
              href="http://iimio.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              飯沢未央
            </a>
            ，
            <a
              href="http://hatayurie.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              畑 ユリエ
            </a>
            ，
            <a
              href="http://shunyahagiwara.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              萩原俊矢
            </a>
            ，
            <a
              href="https://note.mu/akiko_saito"
              target="_blank"
              rel="noopener noreferrer"
            >
              齋藤あきこ
            </a>
          </p>

          <p>
            <a
              href="https://twitter.com/transbookstrans"
              target="_blank"
              rel="noopener noreferrer"
            >
              twitter
            </a>
            /
            <a
              href="https://www.instagram.com/transbooks/"
              target="_blank"
              rel="noopener noreferrer"
            >
              instagram
            </a>
            /
            <a
              href="https://www.facebook.com/transbookstrans/"
              rel="noopener noreferrer"
              target="_blank"
            >
              facebook
            </a>
          </p>
          <p className="small">transbooks.info@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
)

export default IndexPage
